<template>
  <div id="annualInternalAuditPlan">
    <el-dialog
      :title="annualInternalAuditPlanFormTitle"
      width="1200px"
      :visible.sync="annualInternalAuditPlanDialogVisible"
      :close-on-click-modal="false"
      @close="annualInternalAuditPlanDialogClose"
    >
      <el-form
        ref="annualInternalAuditPlanFormRef"
        :model="annualInternalAuditPlanForm"
        :rules="annualInternalAuditPlanFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核计划年份" prop="year">
              <el-input v-model="annualInternalAuditPlanForm.year" placeholder="请输入审核计划年份" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核目的" prop="purpose">
              <el-input v-model="annualInternalAuditPlanForm.purpose" placeholder="请输入审核目的" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核依据" prop="auditBasis">
              <el-input v-model="annualInternalAuditPlanForm.auditBasis" placeholder="请输入审核依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="annualInternalAuditPlanFormTitle !== '年度内部审核计划详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="auditPlanDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="auditTime"
              title="审核时间"
              :edit-render="{name: '$input', props: {type: 'date'}}"
            />
            <vxe-table-column
              field="auditScope"
              title="审核范围"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="auditDept"
              title="审核部门"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="method"
              title="审核方法"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="requirement"
              title="准备工作要求"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="annualInternalAuditPlanFormTitle !== '年度内部审核计划详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="拟制人" prop="editor">
              <el-input v-model="annualInternalAuditPlanForm.editor" placeholder="请输入拟制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="拟制时间" prop="editTime">
              <el-date-picker v-model="annualInternalAuditPlanForm.editTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="annualInternalAuditPlanForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="annualInternalAuditPlanForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="annualInternalAuditPlanForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批准日期" prop="approveTime">
              <el-date-picker v-model="annualInternalAuditPlanForm.approveTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="annualInternalAuditPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="annualInternalAuditPlanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="annualInternalAuditPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="year" label="审核计划年份" />
      <el-table-column prop="purpose" label="审核目的" />
      <el-table-column prop="auditBasis" label="审核依据" />
      <el-table-column prop="editor" label="拟制人" />
      <el-table-column prop="editTime" label="拟制时间" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核日期" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column prop="approveTime" label="批准时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="annualInternalAuditPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAnnualInternalAuditPlan, deleteAnnualInternalAuditPlan, updateAnnualInternalAuditPlan, selectAnnualInternalAuditPlanInfo, selectAnnualInternalAuditPlanList } from '@/api/quality/annualInternalAuditPlan'

export default {
  data () {
    return {
      annualInternalAuditPlanDialogVisible: false,
      annualInternalAuditPlanFormTitle: '',
      annualInternalAuditPlanForm: {
        id: '',
        year: '',
        purpose: '',
        auditBasis: '',
        editor: '',
        editTime: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveTime: '',
        auditPlanDetailJson: ''
      },
      annualInternalAuditPlanFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      annualInternalAuditPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      auditPlanDetailList: []
    }
  },
  created () {
    selectAnnualInternalAuditPlanList(this.searchForm).then(res => {
      this.annualInternalAuditPlanPage = res
    })
  },
  methods: {
    annualInternalAuditPlanDialogClose () {
      this.$refs.annualInternalAuditPlanFormRef.resetFields()
      this.auditPlanDetailList = []
      this.annualInternalAuditPlanForm.id = ''
    },
    annualInternalAuditPlanFormSubmit () {
      if (this.annualInternalAuditPlanFormTitle === '年度内部审核计划详情') {
        this.annualInternalAuditPlanDialogVisible = false
        return
      }
      this.$refs.annualInternalAuditPlanFormRef.validate(async valid => {
        if (valid) {
          this.annualInternalAuditPlanForm.auditPlanDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.annualInternalAuditPlanFormTitle === '新增年度内部审核计划') {
            await addAnnualInternalAuditPlan(this.annualInternalAuditPlanForm)
          } else if (this.annualInternalAuditPlanFormTitle === '修改年度内部审核计划') {
            await updateAnnualInternalAuditPlan(this.annualInternalAuditPlanForm)
          }
          this.annualInternalAuditPlanPage = await selectAnnualInternalAuditPlanList(this.searchForm)
          this.annualInternalAuditPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.annualInternalAuditPlanFormTitle = '新增年度内部审核计划'
      this.annualInternalAuditPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAnnualInternalAuditPlan(row.id)
        if (this.annualInternalAuditPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.annualInternalAuditPlanPage = await selectAnnualInternalAuditPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.annualInternalAuditPlanFormTitle = '修改年度内部审核计划'
      this.annualInternalAuditPlanDialogVisible = true
      this.selectAnnualInternalAuditPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.annualInternalAuditPlanFormTitle = '年度内部审核计划详情'
      this.annualInternalAuditPlanDialogVisible = true
      this.selectAnnualInternalAuditPlanInfoById(row.id)
    },
    selectAnnualInternalAuditPlanInfoById (id) {
      selectAnnualInternalAuditPlanInfo(id).then(res => {
        this.annualInternalAuditPlanForm.id = res.id
        this.annualInternalAuditPlanForm.year = res.year
        this.annualInternalAuditPlanForm.purpose = res.purpose
        this.annualInternalAuditPlanForm.auditBasis = res.auditBasis
        this.annualInternalAuditPlanForm.editor = res.editor
        this.annualInternalAuditPlanForm.editTime = res.editTime
        this.annualInternalAuditPlanForm.reviewer = res.reviewer
        this.annualInternalAuditPlanForm.reviewDate = res.reviewDate
        this.annualInternalAuditPlanForm.approver = res.approver
        this.annualInternalAuditPlanForm.approveTime = res.approveTime
        this.auditPlanDetailList = res.auditPlanDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAnnualInternalAuditPlanList(this.searchForm).then(res => {
        this.annualInternalAuditPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAnnualInternalAuditPlanList(this.searchForm).then(res => {
        this.annualInternalAuditPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAnnualInternalAuditPlanList(this.searchForm).then(res => {
        this.annualInternalAuditPlanPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
